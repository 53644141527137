import axios from 'axios';
import {SearchResult} from '@/models/SearchResult';
import {Suggestion} from "@/models/Suggestion";

export class ProductGroupService {
    private readonly url = `${process.env.VUE_APP_PRODUCT_URL}api/v1/product-group`;

    suggestion(keyword: string, page: number, pageSize: number): Promise<SearchResult<Suggestion<number>>> {
        return axios.get(`${this.url}/suggestion`, {
            params: {
                searchTerm: keyword ? keyword : '',
                page: page,
                pageSize: pageSize
            }
        });
    }
}